@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

body {
  margin: 0;
  overflow-x: hidden;
  font-family: "Poppins", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*::-webkit-scrollbar:horizontal {
  display: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.round-progress {
  height: auto;
  width: 60px;
}
.hover-effect {
  transition: all 0.3s ease;
}

.hover-effect:hover {
  transform: scale(1.1);
  cursor: pointer;
}
.fs-7 {
  font-size: 0.9rem;
}
.transition {
  transition: max-height 0.4s ease-out;
  overflow: hidden;
}
.hidden {
  max-height: 0;
}
.visible {
  max-height: 500px;
}

.sidebar {
  transition: width 0.5s ease, margin 0.3s ease;
}

.sidebar.collapsed {
  width: 70px;
}

.sidebar.expanded {
  width: 220px;
}

/* .collapsed-list {
  padding: 0px;
} */

.collapsed .list-group-item {
  text-align: center;
  padding-left: 0;
  padding-right: 0;
}

.custom-dropdown-toggle::after {
  display: none;
}
.text-success-new {
  color: #18efc0;
}
.button-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 3px;
}
.indicator {
  width: 5px;
  height: 25px;
  border-radius: 4px;
  background-color: #18efc0;
  display: none;
}
.button-wrapper.active-tab .indicator {
  display: block;
}
.button-wrapper.active-tab .btn {
  color: #18efc0;
  background-color: #004172;
}
.bg-primary-dark {
  background-color: #003a66;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(63, 63, 63, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9995;
}

.loading-icon {
  position: absolute;
  z-index: 9999;
  background-color: transparent;
}

.custom-scrollbar {
  scrollbar-width: thin; /* Thin scrollbar for Firefox */
  scrollbar-color: #cacaca #f1f1f1; /* Thumb color and track color for Firefox */
}

.custom-scrollbar::-webkit-scrollbar {
  width: 18px; /* Width of the scrollbar */
  height: 8px; /* Height of the horizontal scrollbar, if any */
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #888; /* Color of the scrollbar thumb */
  border-radius: 4px; /* Rounded corners of the scrollbar thumb */
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Color when hovered */
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1; /* Background of the scrollbar track */
  border-radius: 4px;
}
