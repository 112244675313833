.position-relative {
  position: relative;
}

.circle-container {
  width: 80px;
  height: 80px;
}

.circle-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 0.9rem;
  font-weight: 500;
  text-align: center;
  pointer-events: none;
}
